.fade-in {
  animation: 100ms fadeIn;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.fade-out {
  animation: 100ms fadeOut;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    visibility: hidden;
    opacity: 0;
    transform: scale(0.95);
  }
}
